import React, { useState } from "react";
import automated from "../assets/img/Services/qa-testing/automated.png";
import cost from "../assets/img/Services/qa-testing/cost.png";
import meeting from "../assets/img/Services/qa-testing/meeting.png";
import mobiletesting from "../assets/img/Services/qa-testing/mobile-testing.png";
import robust from "../assets/img/Services/qa-testing/robust.png";
import splaning from "../assets/img/Services/qa-testing/splaning.png";
import ScrollToTop from "../components/ScrollToTop";


function QualityAnalysisTesting() {
    const [activeSection, setActiveSection] = useState('Improved Product Quality');
    const handleTagClick = (section) => {
        setActiveSection(section);
    };

    return (
        <>
            <div className="no-context-menu no-select">
                {/* <!-- Page Title -->  */}
                <div className="page-title dark-background">
                    <div className="heading">
                        <div className="container">
                            <div className="row d-flex justify-content-center text-center">
                                <div className="col-lg-8">
                                    <h1>Quality Analysis & Testing</h1>
                                    <p className="mb-0 b-text">Comprehensive Quality Analysis for Optimal Performance.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <nav className="breadcrumbs">
                        <div className="container">
                            <ol>
                                <li><a href="/">Home</a></li>
                                <li className="current">Quality Analysis & Testing</li>
                            </ol>
                        </div>
                    </nav>
                </div>
                {/* <!-- End Page Title -->  */}

                {/* <!-- Services Section -->  */}
                <section id="services" className="services section">
                    {/* <!-- Section Title -->  */}
                    <div className="container section-title" data-aos="fade-up">
                        <h2>Quality Analysis & Testing Services</h2>
                    </div>
                    {/* <!-- End Section Title -->  */}

                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={splaning} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Strategic Planning</h3>
                                                <p className="card-text">Anantam has a core value of performing robust testing before deploying the product, so it gets zero errors and performs well.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}
                            </div>

                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={meeting} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>QA Team</h3>
                                                <p className="card-text">Anantam has a whole QA team dedicated to quality assurance testing. The product is tested multiple times before going live.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}

                            </div>



                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative">

                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={cost} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Cost-Effective Testing</h3>
                                                <p className="card-text">Anantam gives the best testing solution and that too, within your budget. We will look into the best options for you.</p>
                                                {/* <!-- <p className="card-text"><small className="text-body-secondary">Last updated 3 mins ago</small></p> -->  */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}
                            </div>



                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={automated} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Web Testing Automation</h3>
                                                <p className="card-text">Anantam offers Web Testing Automation service along with the delivery of the project and this helps the end user process a bit easier.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}
                            </div>



                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">

                                <div className="service-item position-relative">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={robust} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Robust Software testing</h3>
                                                <p className="card-text">We make sure to provide suitable software testing services for the software's scalability, responsiveness, and performance. Our team performs a full testing cycle on the application.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}

                            </div>



                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">

                                <div className="service-item position-relative">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={mobiletesting} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Mobile Testing</h3>
                                                <p className="card-text">Our team works on the responsiveness of each screen in each feature using simulators and devices.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- /Services Section -->  */}

                {/* <!-- Service Details Section -->  */}
                <section className="stats section">
                    <div className="container" >
                        <div className="row gy-4 align-items-center">

                            <div className="col-lg-5 section-title" style={{ paddingBottom: '0%' }}>
                                <h2>Quality Analysis & Testing Benefits<br /></h2>
                            </div>

                            <div className="col-lg-7">

                                <div className="row gy-4">
                                    Quality analysis and testing provide numerous benefits for software development and product creation. <br />By rigorously evaluating the product throughout its development lifecycle, organizations can ensure that it meets the highest standards of quality, reliability, and user satisfaction. This, in turn, leads to improved product performance, enhanced user experience, reduced costs, increased customer satisfaction, and a stronger brand reputation.
                                    <div className="col-lg-6">
                                        {/* Quality analysis and testing provide numerous benefits for software development and product creation. By rigorously evaluating the product throughout its development lifecycle, organizations can ensure that it meets the highest standards of quality, reliability, and user satisfaction. This, in turn, leads to improved product performance, enhanced user experience, reduced costs, increased customer satisfaction, and a stronger brand reputation. */}
                                    </div>
                                    {/* <!-- End Stats Item --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="service-details" className="service-details section">

                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                                <div className="services-list">
                                    <a className={activeSection === 'Improved Product Quality' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Improved Product Quality')}>Improved Product Quality</a>
                                    <a className={activeSection === 'Enhanced User Experience' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Enhanced User Experience')}>Enhanced User Experience</a>
                                    <a className={activeSection === 'Increased Customer Satisfaction' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Increased Customer Satisfaction')}>Increased Customer Satisfaction</a>
                                    <a className={activeSection === 'Enhanced Brand Reputation' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Enhanced Brand Reputation')}>Enhanced Brand Reputation</a>
                                    <a className={activeSection === 'Data-Driven Decision Making' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Data-Driven Decision Making')}>Data-Driven Decision Making</a>
                                </div>
                            </div>

                            <div className="col-lg-8" data-aos="fade-up" data-aos-delay="200">
                                <h3>{activeSection}</h3>
                                <p>{activeSection === 'Improved Product Quality' && 'By rigorously testing and analyzing products, organizations can significantly improve their quality. This involves identifying and addressing defects early in the development cycle, ensuring that products are reliable, robust, secure, usable, and performant. This meticulous approach leads to products that function consistently, tolerate various conditions, are protected from vulnerabilities, are easy to use, and respond efficiently. Ultimately, this translates to increased customer satisfaction, reduced support costs, and a stronger brand reputation.'}</p>
                                <p>{activeSection === 'Enhanced User Experience' && 'Quality analysis and testing play a pivotal role in creating seamless and enjoyable user experiences. By rigorously testing products, organizations can identify and address usability issues, performance bottlenecks, and accessibility barriers. This results in products that are intuitive, responsive, and visually appealing. When users have positive experiences with a product, they are more likely to become loyal customers and advocate for the brand.'}</p>
                                <p>{activeSection === 'Increased Customer Satisfaction' && 'By delivering high-quality products, quality analysis and testing directly contribute to increased customer satisfaction. When products function as expected, are reliable, and meet user needs, customers are more likely to be happy and satisfied. This can lead to positive reviews, word-of-mouth recommendations, and increased customer loyalty. Additionally, by addressing issues early in the development process, quality assurance can prevent frustrating user experiences and negative feedback.'}</p>
                                <p>{activeSection === 'Enhanced Brand Reputation' && 'A well-tested and high-quality product can significantly enhance a brand\'s reputation. When customers have positive experiences with a product, they are more likely to trust the brand and recommend it to others. This can lead to increased brand loyalty, positive word-of-mouth, and improved brand perception. Additionally, by addressing issues early in the development process, quality assurance can prevent negative publicity and damage to the brand\'s reputation.'}</p>
                                <p>{activeSection === 'Data-Driven Decision Making' && 'Data-driven decision-making involves using facts, metrics, and data to guide strategic business decisions that align with your goals, objectives, and initiatives. By leveraging data, organizations can make more informed, accurate, and effective decisions.'}</p>
                                <ul>
                                    <li><i className="bi bi-check-circle"></i> <span>{activeSection === 'Improved Product Quality' ? 'Reliable' : activeSection === 'Enhanced User Experience' ? 'Seamless Functionality' : activeSection === 'Increased Customer Satisfaction' ? 'Enhanced Brand Reputation' : activeSection === 'Enhanced Brand Reputation' ? 'Build Trust' : activeSection === 'Data-Driven Decision Making' ? 'Increased Revenue' : ''}</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>{activeSection === 'Improved Product Quality' ? 'Robust' : activeSection === 'Enhanced User Experience' ? 'Fast Performance' : activeSection === 'Increased Customer Satisfaction' ? 'Reduced Customer Support Costs' : activeSection === 'Enhanced Brand Reputation' ? 'Improve Brand Perception' : activeSection === 'Data-Driven Decision Making' ? 'Enhanced Efficiency' : ''}</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>{activeSection === 'Improved Product Quality' ? 'Performant' : activeSection === 'Enhanced User Experience' ? 'Error Prevention' : activeSection === 'Increased Customer Satisfaction' ? 'Increased Customer Loyalty' : activeSection === 'Enhanced Brand Reputation' ? 'Attract New Customers' : activeSection === 'Data-Driven Decision Making' ? 'Data Analysis' : ''}</span></li>
                                </ul>
                            </div>
                            {/* help-box */}
                            <div class="help-box d-flex flex-column justify-content-center align-items-center">
                                <i class="bi bi-headset help-icon"></i>
                                <h4>Have a Question?</h4>
                                <p class="d-flex align-items-center mt-2 mb-0"><i class="bi bi-telephone me-2"></i> <span>+91 972727 0155</span></p>
                                <p class="d-flex align-items-center mt-1 mb-0"><i class="bi bi-envelope me-2"></i> <a href="mailto:hello@anantamit.com">hello@anantamit.com</a></p>
                            </div>
                            {/* help-box finish*/}
                        </div>
                    </div>
                </section>
                {/* <!-- /Service Details Section -->  */}

                {/* web application development Teck track  */}
                <section className="section ">
                    <div className="container section-title" data-aos="fade-up">
                        <h2>Our QA and Testing Tech Stack<br /></h2>
                    </div>
                    <div className="container ">
                        <div style={{ display: 'flex' }}>
                            <div className="col-sm-4 ais-ts-head">
                                <span className="ais-ts-text">Test Management Tools</span>
                            </div>
                            <div className="col-sm-8 ais-ts-data ">
                                <div className="ais-ts-text">
                                    <ul>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>TestRail</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Jira</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Zephyr Scale</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <div className="col-sm-4 ais-ts-head">
                                <span className="ais-ts-text">Test Automation Tools</span>
                            </div>
                            <div className="col-sm-8 ais-ts-data ">
                                <div className="ais-ts-text">
                                    <ul>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Selenium WebDriver</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Appium</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Cypress</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>PyTest</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <div className="col-sm-4 ais-ts-head">
                                <span className="ais-ts-text">Security Testing Tools</span>
                            </div>
                            <div className="col-sm-8 ais-ts-data ">
                                <div className="ais-ts-text">
                                    <ul>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Burp Suite</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>OWASP ZAP</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Nessus</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <ScrollToTop />
        </>
    );
}

export default QualityAnalysisTesting;