import React, { useState } from "react";
import aiml from "../assets/img/Services/softwareprodEng/aiml.png";
import embeddedsofDev from "../assets/img/Services/softwareprodEng/embeddedsofDev.png";
import enterprisesoftsolution from "../assets/img/Services/softwareprodEng/enterprisesoftsolution.png";
import legacysys from "../assets/img/Services/softwareprodEng/legacysys.png";
import softwareaecdev from "../assets/img/Services/softwareprodEng/softwareaecdev.png";
import softwareproddev from "../assets/img/Services/softwareprodEng/softwareproddev.png";
import ScrollToTop from "../components/ScrollToTop";

function SoftwareProductEng() {
    const [activeSection, setActiveSection] = useState('Reliable Solutions');
    const handleTagClick = (section) => {
        setActiveSection(section);
    };

    return (
        <>
            <div className="no-context-menu no-select">
                {/* <!-- Page Title -->  */}
                <div className="page-title dark-background">
                    <div className="heading">
                        <div className="container">
                            <div className="row d-flex justify-content-center text-center">
                                <div className="col-lg-8">
                                    <h1>Software Product Engineering</h1>
                                    <p className="mb-0 b-text">Together, we build great products.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <nav className="breadcrumbs">
                        <div className="container">
                            <ol>
                                <li><a href="/">Home</a></li>
                                <li className="current">Software Product Engineering</li>
                            </ol>
                        </div>
                    </nav>
                </div>
                {/* <!-- End Page Title -->  */}

                {/* <!-- Services Section -->  */}
                <section id="services" className="services section">
                    {/* <!-- Section Title -->  */}
                    <div className="container section-title" data-aos="fade-up">
                        <h2>Software Product Engineering Services</h2>
                    </div>
                    {/* <!-- End Section Title -->  */}

                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative hover-hand">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={softwareaecdev} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Software Architecture Design</h3>
                                                <p className="card-text">We provide expert guidance on designing scalable and efficient software architectures.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}
                            </div>

                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative hover-hand">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={softwareproddev} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Software Product Development</h3>
                                                <p className="card-text">From concept to launch, we guide you through the entire product development lifecycle, ensuring a successful outcome.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}

                            </div>

                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative hover-hand ">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={enterprisesoftsolution} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Enterprise Software Solutions</h3>
                                                <p className="card-text">We build scalable and robust software solutions that can handle the demands of large-scale enterprises.</p>
                                                {/* <!-- <p className="card-text"><small className="text-body-secondary">Last updated 3 mins ago</small></p> -->  */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}
                            </div>

                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item position-relative hover-hand">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={embeddedsofDev} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Embedded Software Development</h3>
                                                <p className="card-text">We develop software for embedded systems, integrating it seamlessly into hardware devices.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}
                            </div>

                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">

                                <div className="service-item position-relative hover-hand">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={legacysys} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Legacy System Modernization</h3>
                                                <p className="card-text">We help you update outdated software systems to improve performance, security, and compatibility. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}
                            </div>

                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">

                                <div className="service-item position-relative hover-hand">
                                    <div className="row g-0">
                                        <div className="col-md-4 container d-flex align-items-center justify-content-center">
                                            <div className="icon">
                                                <img src={aiml} alt="" />
                                                {/* <i className="bi bi-phone"></i>  */}
                                            </div>
                                        </div>

                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h3>Artificial Intelligence and Machine Learning</h3>
                                                <p className="card-text">We develop AI-powered solutions to automate tasks, improve decision-making, and gain valuable insights from data.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Service Item -->  */}
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- /Services Section -->  */}

                {/* <!-- Service Details Section -->  */}
                <section className="stats section">
                    <div className="container" >
                        <div className="row gy-4 align-items-center">
                            <div className="col-lg-5 section-title" style={{ paddingBottom: '0%' }}>
                                <h2>Software Product Engineering Benefits<br /></h2>
                            </div>
                            <div className="col-lg-7">
                                <div className="row gy-4">
                                    <p>Custom software development and product development can be powerful tools for businesses seeking to streamline operations, enhance efficiency, and gain a competitive edge. By tailoring software solutions to specific needs and goals, organizations can automate repetitive tasks, integrate seamlessly with existing systems, and adapt to evolving business requirements. This not only improves productivity but also enhances customer satisfaction and drives overall growth. Moreover, custom software and product development can foster innovation and differentiation, enabling businesses to stand out in their respective markets.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="service-details" className="service-details section">
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                                <div className="services-list">
                                    <a className={activeSection === 'Reliable Solutions' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Reliable Solutions')}>Reliable Solutions</a>
                                    <a className={activeSection === 'Enterprise-Grade Security' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Enterprise-Grade Security')}>Enterprise-Grade Security</a>
                                    <a className={activeSection === 'Admin ownership' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Admin ownership')}>Admin ownership</a>
                                    <a className={activeSection === 'Ongoing Maintenance and Support' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Ongoing Maintenance and Support')}>Ongoing Maintenance and Support</a>
                                    {/* <a className={activeSection === 'Dedicated App Developers' ? 'active' : ''} style={{ cursor: 'pointer' }}
                                        onClick={() => handleTagClick('Dedicated App Developers')}>Dedicated App Developers</a> */}
                                </div>
                            </div>

                            <div className="col-lg-8" data-aos="fade-up" data-aos-delay="200">
                                <h3>{activeSection}</h3>
                                <p>{activeSection === 'Reliable Solutions' && 'Our commitment to delivering exceptional software solutions is underpinned by a rigorous development process and a focus on quality assurance. We employ state-of-the-art technologies and methodologies to ensure that our products are reliable, scalable, and secure. Our team of skilled developers adheres to industry best practices and follows a structured approach that includes meticulous planning, coding, testing, and deployment. By prioritizing quality throughout the development lifecycle, we consistently exceed client expectations and deliver software that drives business success.'}</p>
                                <p>{activeSection === 'Enterprise-Grade Security' && 'Our custom software development services are designed with security as a top priority. We understand the critical importance of protecting your sensitive data and systems from unauthorized access, data breaches, and other security threats. To ensure the highest level of security, we implement a comprehensive suite of robust measures'}</p>
                                <p>{activeSection === 'Admin ownership' && 'At Anantam, we believe in empowering our clients with complete control over their custom software solutions. That\'s why we provide full ownership of the software, ensuring that you have the exclusive rights to use, modify, and distribute it as you see fit. This means you\'ll have unrestricted access to the source code, allowing you to make changes and customizations to meet your evolving business needs.'}</p>
                                <p>{activeSection === 'Ongoing Maintenance and Support' && 'Our commitment to excellence doesn\'t end with the development phase. We understand that software is a living entity that requires ongoing care and attention to ensure its continued success. That\'s why we offer comprehensive post-software development support services designed to keep your system functioning optimally and meeting your evolving needs.'}</p>
                                <p>{activeSection === 'Dedicated App Developers' && 'At Anantam, we offer dedicated app developers who are committed to delivering exceptional mobile app solutions tailored to your specific needs. Our experienced and skilled developers work exclusively on your project, ensuring personalized attention, rapid development cycles, and seamless communication. By partnering with Anantam, you gain access to a dedicated team of experts who are fully invested in your success, delivering high-quality mobile apps that exceed your expectations.'}</p>
                                <ul>
                                    <li><i className="bi bi-check-circle"></i> <span>{activeSection === 'Reliable Solutions' ? 'Documentation' : activeSection === 'Enterprise-Grade Security' ? 'Data Privacy' : activeSection === 'Admin ownership' ? 'Independence' : activeSection === 'Ongoing Maintenance and Support' ? 'Technical Support' : ''}</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>{activeSection === 'Reliable Solutions' ? 'Performance' : activeSection === 'Enterprise-Grade Security' ? 'Reduced Vulnerabilities' : activeSection === 'Admin ownership' ? 'Intellectual Property Rights' : activeSection === 'Ongoing Maintenance and Support' ? 'Enhancements and Upgrades' : ''}</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>{activeSection === 'Reliable Solutions' ? 'Updatability' : activeSection === 'Enterprise-Grade Security' ? 'Firewall protection' : activeSection === 'Admin ownership' ? 'Customization Freedom' : activeSection === 'Ongoing Maintenance and Support' ? 'Maintenance and Optimization' : ''}</span></li>
                                </ul>
                            </div>
                            {/* help-box */}
                            <div class="help-box d-flex flex-column justify-content-center align-items-center">
                                <i class="bi bi-headset help-icon"></i>
                                <h4>Have a Question?</h4>
                                <p class="d-flex align-items-center mt-2 mb-0"><i class="bi bi-telephone me-2"></i> <span>+91 972727 0155</span></p>
                                <p class="d-flex align-items-center mt-1 mb-0"><i class="bi bi-envelope me-2"></i> <a href="mailto:hello@anantamit.com">hello@anantamit.com</a></p>
                            </div>
                            {/* help-box finish*/}
                        </div>
                    </div>
                </section>
                {/* <!-- /Service Details Section -->  */}


                {/* web application development Teck track  */}
                <section className="section ">
                    <div className="container section-title" data-aos="fade-up">
                        <h2>Our Software Product Engineering Tech Stack<br /></h2>
                    </div>
                    <div className="container ">
                        <div style={{ display: 'flex' }}>
                            <div className="col-sm-4 ais-ts-head">
                                <span className="ais-ts-text">Front-End</span>
                            </div>
                            <div className="col-sm-8 ais-ts-data ">
                                <div className="ais-ts-text">
                                    <ul>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>React JS</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>VueJS</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Angular JS</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Javascript</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>PHP</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <div className="col-sm-4 ais-ts-head">
                                <span className="ais-ts-text">Back-End</span>
                            </div>
                            <div className="col-sm-8 ais-ts-data ">
                                <div className="ais-ts-text">
                                    <ul>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Node js</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Spring Boot</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Python</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Laravel</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>Django</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <div className="col-sm-4 ais-ts-head">
                                <span className="ais-ts-text">Database</span>
                            </div>
                            <div className="col-sm-8 ais-ts-data ">
                                <div className="ais-ts-text">
                                    <ul>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>MS SQL</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>MySQL</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>SQLite</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>MongoDB</li>
                                        <li> <i className="bi bi-check-circle-fill" style={{ marginRight: 10 }} ></i>PostgreSQL</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <ScrollToTop />
        </>
    );
}

export default SoftwareProductEng;