import React, { useRef } from "react";
import emailjs from '@emailjs/browser';

function ContactUs({ contactRef }) {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('service_tzbseo7', 'template_9srsqep', form.current, {
        publicKey: 'hxD9PWFPd-BWu5Fa9',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <section id="contact" className="contact section" ref={contactRef} >
      <div className="container section-title" data-aos="fade-up">
        <h2>Contact</h2>
        {/* <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p> */}
      </div>

      <div className="container" data-aos="fade-up" data-aos-delay="100">

        <div className="row gx-lg-0 gy-4">

          <div className="col-lg-4">
            <div className="info-container d-flex flex-column align-items-center justify-content-center">
              <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="200">
                <i className="bi bi-geo-alt flex-shrink-0"></i>
                <div>
                  <h3>Address</h3>
                  <p>413 Harmony Icon, Thaltej, Ahmedabad, Gujarat, India 380059.</p>
                </div>
              </div>

              <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
                <i className="bi bi-telephone flex-shrink-0"></i>
                <div>
                  <h3>Call Us</h3>
                  <p>+91 972727 0155</p>
                </div>
              </div>

              <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
                <i className="bi bi-envelope flex-shrink-0"></i>
                <div>
                  <h3>Email Us</h3>
                  <p>hello@anantamit.com</p>
                </div>
              </div>

            </div>

          </div>

          <div className="col-lg-8">
            <form className="php-email-form" data-aos-delay="100" ref={form} onSubmit={sendEmail}>
              <div className="row gy-4">

                <div className="col-md-6">
                  <input type="text" id="name" class="form-control" name="user_name" placeholder="Your Name" required="" />
                </div>

                <div className="col-md-6 ">
                  <input type="email" id="email" class="form-control" name="user_email" placeholder="Your Email" required="" />
                </div>

                <div className="col-md-12">
                  <input type="text" id="subject" class="form-control" name="subject" placeholder="Subject" required="" />
                </div>

                <div className="col-md-12">
                  <textarea className="form-control" id="message" class="form-control" name="message" rows="8" placeholder="Message" required=""></textarea>
                </div>

                <div className="col-md-12 text-center">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">Your message has been sent. Thank you!</div>

                  <button type="submit" onSubmit={sendEmail} >Send Message</button>
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
export default ContactUs;