import React, { useEffect } from 'react';

function Preloader() {

  useEffect(() => {
    const preloader = document.getElementById('preloader');

    if (preloader) {
      const onLoadHandler = () => {
        preloader.style.opacity = 0;
        setTimeout(() => {
          preloader.style.display = 'none';
        }, 600);
      };
      window.addEventListener('load', onLoadHandler);
      const fallbackTimer = setTimeout(() => {
        if (preloader.style.display !== 'none') {
          preloader.style.opacity = 0;
          setTimeout(() => {
            preloader.style.display = 'none';
          }, 600);
        }
      }, 2000);
      return () => {
        window.removeEventListener('load', onLoadHandler);
        clearTimeout(fallbackTimer); // Clear fallback timer
      };
    }
  }, []);

  return (
    <div id="preloader"></div>
  );
}

export default Preloader;