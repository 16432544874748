import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import sanavi1 from "../assets/img/portfolio/SANAVI/sanavi-1.jpg";
import sanavi2 from "../assets/img/portfolio/SANAVI/sanavi-2.jpg";
import sanavi3 from "../assets/img/portfolio/SANAVI/sanavi-3.jpg";
import sanavi4 from "../assets/img/portfolio/SANAVI/sanavi-4.jpg";
import ScrollToTop from '../components/ScrollToTop';

function SanaviPortfolio() {
    const images = [
        // Add your image URLs here
        sanavi1, sanavi2, sanavi3, sanavi4
        // ...
    ];
    return (
        <>
            <div className="page-title">
                <div className="heading">
                    <div className="container">
                        <div className="row d-flex justify-content-center text-center">
                            <div className="col-lg-8">
                                <h1>Portfolio Details</h1>
                                <p className="mb-0">A Detailed Look at Our Projects</p>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="breadcrumbs">
                    <div className="container">
                        <ol>
                            <li><a href="/">Home</a></li>
                            <li className="current">Portfolio Details</li>
                        </ol>
                    </div>
                </nav>
            </div>
            {/* <!-- End Page Title --> */}

            {/* <!-- Portfolio Details Section --> */}
            <section id="portfolio-details" className="portfolio-details section">
                <div className="container" data-aos="fade-up">
                    <div className="portfolio-details-slider swiper init-swiper">
                        <Swiper
                            modules={[Navigation, Pagination]}
                            spaceBetween={30}
                            slidesPerView={1}
                            navigation
                            pagination={{ clickable: true }}
                        >
                            {images.map((image, index) => (
                                <SwiperSlide key={index}>
                                    <img src={image} alt={`Portfolio Image ${index + 1}`} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>

                    <div className="row justify-content-between gy-4 mt-4">

                        <div className="col-lg-8" data-aos="fade-up">
                            <div className="portfolio-description">
                                {/* <h2>Technical Training Alliance</h2> */}
                                <p><b>Website that showcases their infrastructure, safety measures, and work portfolio</b></p>

                                <h2>Client’s requirements</h2>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The client has requested a website that showcases their infrastructure, safety measures, and work portfolio. The website should include an image gallery and detailed descriptions for select projects. A responsive design is essential to ensure optimal viewing across devices. Additionally, the client wants their affiliations prominently displayed, a contact form for easy communication, and a dedicated section to showcase their clients and testimonials.</p>

                                <h2>Key requirement</h2>
                                <ul>
                                    <li><span>Responsive Design for Optimal User Experience</span></li>
                                    <li><span>Clear and Accessible Contact Information</span></li>
                                    <li><span>Client Testimonials and Case Studies </span></li>
                                    <li><span>Secure and Optimized Website Performance </span></li>
                                </ul>

                                <h2>Approach</h2>
                                <p>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To meet the client's requirements, we'll create a visually appealing and user-friendly website that effectively highlights their infrastructure, safety measures, and work portfolio. The homepage will feature a captivating image gallery showcasing their most impressive projects. Key sections will include detailed project descriptions, a responsive design for seamless viewing on all devices, a prominent display of affiliations, a convenient contact form, and a dedicated area to showcase client testimonials and case studies. This comprehensive approach will not only showcase the client's capabilities but also foster trust and credibility among potential clients.
                                </p>


                                <h2>Solutions We Provided</h2>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To address the client's needs, we'll develop a custom website featuring a visually stunning design, clear navigation, and informative content. The site will showcase their infrastructure, safety measures, and work portfolio through high-quality images and detailed project descriptions. We'll ensure optimal viewing across devices with a responsive design and provide a user-friendly contact form for easy communication. Additionally, we'll prominently display their affiliations and create a dedicated section to highlight client testimonials and case studies. This comprehensive solution will effectively position the client as a reliable and trustworthy partner in their industry.
                                </p>



                                <h2>Key features</h2>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The website will incorporate several key features to enhance its functionality and user experience. These include a visually appealing and intuitive design, clear navigation, and informative content. High-quality images and detailed project descriptions will showcase the client's infrastructure, safety measures, and work portfolio. A responsive design will ensure optimal viewing on all devices, while a user-friendly contact form will facilitate easy communication with potential clients. Additionally, the website will prominently display the client's affiliations and feature a dedicated section to highlight client testimonials and case studies. These features will collectively contribute to a professional and engaging online presence.
                                </p>
                                {/* <div className="testimonial-item">
                                    <p>
                                        <i className="bi bi-quote quote-icon-left"></i>
                                        <span>Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.</span>
                                        <i className="bi bi-quote quote-icon-right"></i>
                                    </p>
                                    <div>
                                        <img src="assets/img/testimonials/testimonials-2.jpg" className="testimonial-img" alt="" />
                                        <h3>Sara Wilsson</h3>
                                        <h4>Designer</h4>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                        <div className="col-lg-3" data-aos="fade-up" data-aos-delay="100">
                            <div className="portfolio-info">
                                <h3>Project information</h3>
                                <ul>
                                    <li><strong>Category</strong> Web Development</li>
                                    <li><strong>Client</strong> Sanavi Infrabuildcon PVT. LTD</li>
                                    {/* <li><strong>Project date</strong> 01 March, 2024</li>
                                    <li><strong>Project URL</strong> <a href="#">https://thettainc.com/</a></li>
                                    <li><a href="#" className="btn-visit align-self-start">Visit Website</a></li> */}
                                </ul>
                            </div>
                        </div>

                    </div>

                </div>

            </section>
            {/* <!-- /Portfolio Details Section --> */}
            <ScrollToTop />
        </>
    );
}
export default SanaviPortfolio;