import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

import TTA1 from "../assets/img/portfolio/TTA/TTA-1.jpg";
import TTA2 from "../assets/img/portfolio/TTA/TTA-2.jpg";
import TTA3 from "../assets/img/portfolio/TTA/TTA-3.jpg";
import TTA4 from "../assets/img/portfolio/TTA/TTA-4.jpg";
import TTA5 from "../assets/img/portfolio/TTA/TTA-5.jpg";
import TTA6 from "../assets/img/portfolio/TTA/TTA-6.jpg";
import TTA7 from "../assets/img/portfolio/TTA/TTA-7.jpg";
import TTA8 from "../assets/img/portfolio/TTA/TTA-8.jpg";
import TTA9 from "../assets/img/portfolio/TTA/TTA-9.jpg";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import 'swiper/css/pagination';
import ScrollToTop from '../components/ScrollToTop';


function PortfolioDetails() {
    const images = [
        // Add your image URLs here
        TTA1, TTA2, TTA3, TTA4, TTA5, TTA6, TTA7, TTA8, TTA9
        // ...
    ];
    return (
        <>
            <div className="page-title">
                <div className="heading">
                    <div className="container">
                        <div className="row d-flex justify-content-center text-center">
                            <div className="col-lg-8">
                                <h1>Portfolio Details</h1>
                                <p className="mb-0">A Detailed Look at Our Projects</p>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="breadcrumbs">
                    <div className="container">
                        <ol>
                            <li><a href="/">Home</a></li>
                            <li className="current">Portfolio Details</li>
                        </ol>
                    </div>
                </nav>
            </div>
            {/* <!-- End Page Title --> */}

            {/* <!-- Portfolio Details Section --> */}
            <section id="portfolio-details" className="portfolio-details section">
                <div className="container" data-aos="fade-up">
                    <div className="portfolio-details-slider swiper init-swiper">
                        <Swiper
                            modules={[Navigation, Pagination]}
                            spaceBetween={30}
                            slidesPerView={1}
                            navigation
                            pagination={{ clickable: true }}
                        >
                            {images.map((image, index) => (
                                <SwiperSlide key={index}>
                                    <img src={image} alt={`Portfolio Image ${index + 1}`} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>

                    <div className="row justify-content-between gy-4 mt-4">

                        <div className="col-lg-8" data-aos="fade-up">
                            <div className="portfolio-description">
                                {/* <h2>Technical Training Alliance</h2> */}
                                <p><b>This platform offers a wide variety of online courses on a variety of topics.</b></p>

                                <h2>Client’s requirements</h2>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The client envisioned a comprehensive online platform dedicated to technical education. They required the development of a website where users could purchase and access a variety of technical courses. Additionally, they wanted to integrate a robust video platform to facilitate online learning, ensuring seamless streaming and user-friendly navigation. The entire website was to be built using WordPress to leverage its flexibility, scalability, and ease of content management. The ultimate goal was to create an intuitive, engaging, and efficient platform that would empower users to enhance their technical skills through high-quality online courses.</p>

                                <h2>Key requirement</h2>
                                <ul>
                                    <li><span>WooCommerce Integration</span></li>
                                    <li><span>PayPal Integration</span></li>
                                    <li><span>Website speed optimization and google speed index</span></li>
                                    <li><span>Webinars</span></li>
                                </ul>

                                <h2>Approach</h2>
                                <p>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Our development team created a thorough roadmap to realize the project after comprehending the client's goal. To ensure a smooth transfer to WordPress designs, we started by scripting and converting all required pages to an Elementor-based theme. We performed extensive database optimization during the transfer process, and every page was carefully modified to ensure the best possible outcomes.
                                    <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We linked the website with PayPal and WooCommerce to enable safe and easy online transactions. Our team also concentrated on improving the website's speed, making sure that loading times were rapid and effective for a better user experience.
                                </p>


                                <h2>Solutions We Provided</h2>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Our team meticulously adapted the website architecture using advanced plugins, ensuring a seamless migration of all product pages in line with the client’s specifications. During the customization process, we ensured that plugin updates would not disrupt the website’s functionality. We also facilitated the client’s transition to a new hosting provider without any downtime.<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To enhance the website’s performance, we focused on speed optimization by identifying and addressing repetitive and time-consuming requests. Utilizing GTmetrix, we optimized these elements to ensure faster loading times. Additionally, we integrated PayPal with WooCommerce to provide a secure and efficient payment system, ensuring a smooth checkout experience for users.
                                </p>



                                <h2>Key features</h2>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The website architecture was developed by our development team, and all product pages were successfully transferred. To enable easy and safe online transactions, we improved the website's functionality and incorporated PayPal and WooCommerce. Furthermore, without causing any website disruptions, we helped the client move hosting providers. To guarantee a smooth transition into WordPress designs, each page was transformed into an Elementor-based theme and manually adjusted. We optimized the database throughout the migration to guarantee optimal website performance. Together, these efforts produced a strong, effective, and intuitive platform that satisfies the needs of the customer and improves the user experience in general.
                                </p>
                                {/* <div className="testimonial-item">
                                    <p>
                                        <i className="bi bi-quote quote-icon-left"></i>
                                        <span>Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.</span>
                                        <i className="bi bi-quote quote-icon-right"></i>
                                    </p>
                                    <div>
                                        <img src="assets/img/testimonials/testimonials-2.jpg" className="testimonial-img" alt="" />
                                        <h3>Sara Wilsson</h3>
                                        <h4>Designer</h4>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                        <div className="col-lg-3" data-aos="fade-up" data-aos-delay="100">
                            <div className="portfolio-info">
                                <h3>Project information</h3>
                                <ul>
                                    <li><strong>Category</strong> Web Development</li>
                                    <li><strong>Client</strong> TTA Company</li>
                                    {/* <li><strong>Project date</strong> 01 March, 2024</li>
                                    <li><strong>Project URL</strong> <a href="#">https://thettainc.com/</a></li>
                                    <li><a href="#" className="btn-visit align-self-start">Visit Website</a></li> */}
                                </ul>
                            </div>
                        </div>

                    </div>

                </div>

            </section>
            {/* <!-- /Portfolio Details Section --> */}
            <ScrollToTop />
        </>
    );
}
export default PortfolioDetails;