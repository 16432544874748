import React from "react";
import './assets/css/main.css'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ContactUs from "./components/ContactUs";
import ServicesDetail from "./pages/ServicesDetail";
import WebAppService from "./pages/WebAppService";
import SoftwareProductEng from "./pages/SoftwareProductEng";
import UiUxService from "./pages/UiUxService";
import SEODigitalMarketing from "./pages/SEODigitalMarketing";
import QualityAnalysisTesting from "./pages/QualityAnalysisTesting";
import Preloader from "./components/Preloader";
import PortfolioDetails from "./pages/PortfolioDetails";
import NictPortfolio from "./pages/NictPortfolio";
import SanaviPortfolio from "./pages/SanaviPortfolio";
import HireDeveloper from "./pages/HireDeveloper";
import Demo from "./pages/Demo";
import AboutUs from "./pages/AboutUs";
import Career from "./pages/Career";

function App() {
  return (

    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route exact path="/" Component={HomePage} />
          <Route path="/service-detail" Component={ServicesDetail} />
          <Route path="/contactus" Component={ContactUs} />
          <Route path="/web-app-service" Component={WebAppService} />
          <Route path="/software-prod-eng" Component={SoftwareProductEng} />
          <Route path="/ui-ux-service" Component={UiUxService} />
          <Route path="/seo-digitalmarketing" Component={SEODigitalMarketing} />
          <Route path="/quality-analysis-testing" Component={QualityAnalysisTesting} />
          <Route path="/portfolio-details" Component={PortfolioDetails} />
          <Route path="/nict-details" Component={NictPortfolio} />
          <Route path="/sanavi-details" Component={SanaviPortfolio} />
          <Route path="/hire-developer/:framework" Component={HireDeveloper} />
          <Route path="/about-us" Component={AboutUs} />

          <Route path="/careers-at-anantam" Component={Career} />
        </Routes>
        <Footer />

        <Preloader />
      </div>
    </Router>
  );
}

export default App;
