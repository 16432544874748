import React, { useRef } from "react";
import HeroBanner from "../assets/img/hero-img.png";

function HeroSection({ scrollToContact }) {
    return (
        <section id="hero" className="hero section accent-background ">
            <div className="container-fluid">
                <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
                    <div className="row gy-5 justify-content-between">
                        <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                            <h6>Boundless Development, Your Scalable Future</h6>
                            <h2><span>“Build Better</span><span className="accent"> Web Applications.”</span></h2>
                            <p>"Let us manage all your technical needs, so you can focus on growing your business"</p>
                            <div className="d-flex">
                                {/* <a href="#contact" className="btn-get-started mb-3" >Let’s connect</a> */}
                                <button className="btn-get-started mb-3"
                                    onClick={scrollToContact}>
                                    Let’s connect
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-5 order-1 order-lg-2">
                            <img src={HeroBanner} className="img-fluid" alt="bc" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default HeroSection;