import React, { useState, useEffect } from 'react';
import data from '../data.json'
import ContactUs from '../components/ContactUs';
import { useParams } from 'react-router-dom';

function Career() {

    return (
        <>
            {/* <!-- Page Title -->  */}
            <div className="page-title">
                <div className="heading cover-hire">
                    <div className="container">
                        <div className="row d-flex justify-content-center text-center">
                            <div className="col-lg-8">
                                <h1>Career and Culture</h1>
                                <p className="mb-0">Are you a team player looking to carve out an impactful career for yourself? Then come join us.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <nav className="breadcrumbs">
                    <div className="container">
                        <ol>
                            <li><a href="/">Home</a></li>
                            <li className="current">Career </li>
                        </ol>
                    </div>
                </nav>
            </div>
            {/* <!-- End Page Title --> */}

            <section className="contact section">
                <div className="container section-title" data-aos="fade-up">
                    <h2>Join us Anantam as we help you build your career, it's not just a job.</h2>
                    <p>Are you a tech-savvy individual looking to join a dynamic and innovative IT company? Look no further! Our company values
                        <br /> creativity, collaboration, and continuous learning. Join our team and be part of a culture that encourages personal and
                        <br />professional growth. Apply now and take the first step towards a fulfilling career in IT.</p>
                </div>
            </section>

            <section className="contact section ais-background">
                <div className="container section-title" data-aos="fade-up">
                    <h2>Current Openings</h2>
                    <p>Sorry! No jobs to show.</p>
                </div>
            </section>

            {/* <!-- perk of working Section --> */}
            <section id="why-us" className="why-us section">
                <div className="container">
                    <div className="container section-title" data-aos="fade-up"><h2>Perks of working at Anantam</h2></div>
                    <div className="row no-gutters">

                        <div className="col-lg-4 col-md-6 card" data-aos="fade-up" >
                            <span>01</span>
                            <h4>Extensive Growth Opportunity</h4>
                            <p>Achieve your professional goals with our extensive growth opportunities and career advancement programs.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 card" data-aos="fade-up" >
                            <span>02</span>
                            <h4>Empowering Learning New Tech</h4>
                            <p>Empower your tech skills through our innovative and cutting-edge learning opportunities.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 card" data-aos="fade-up" >
                            <span>03</span>
                            <h4>Rewards and appreciation</h4>
                            <p>Rewarding our employees for their hard work is an important part of our culture.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 card" data-aos="fade-up" >
                            <span>04</span>
                            <h4>Open Culture</h4>
                            <p>Experience a welcoming and inclusive open culture. We appreciate the diversity in beliefs, cultures, and people.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 card" data-aos="fade-up" >
                            <span>05</span>
                            <h4>Team Bond</h4>
                            <p>Engage in indoor and outdoor team bonding activities and events.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 card" data-aos="fade-up" >
                            <span>06</span>
                            <h4>Fun to Work With</h4>
                            <p>Join a team that values collaboration and is a fun to work with.</p>
                        </div>

                    </div>
                </div>
            </section>
            {/* <!-- /perk of working Section --> */}

            {/* 
            <section className="contact section">
                <div className="container section-title" data-aos="fade-up">
                    <h2>Quick Questions & Answers</h2>
                </div>
            </section> */}


            {/* <!-- Contact Section --> */}
            <ContactUs />
            {/* <!-- /Contact Section --> */}
        </>
    );
}

export default Career;
