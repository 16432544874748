import React from "react";
import stats from "../assets/img/stats.png";
// import about from "../assets/img/about.png";
import purecounter from "@srexi/purecounterjs";
import ourStory from "../assets/img/anantam-way.png";
import anantamWay from "../assets/img/our-story.png";
import think from "../assets/img/think.png";
import ContactUs from "../components/ContactUs";

function AboutUs() {
    React.useEffect(() => {
        // Initialize PureCounter instances
        const counters = document.querySelectorAll('.purecounter');
        counters.forEach((counter) => {
            new purecounter(counter);
        });
    }, []);
    return (
        <>
            {/* <!-- Page Title -->  */}
            <div className="page-title">
                <div className="heading cover-hire">
                    <div className="container">
                        <div className="row d-flex justify-content-center text-center">
                            <div className="col-lg-8">
                                <h1>About Anantam</h1>
                                <p className="mb-0">Leveraging Technology to Drive Business Growth.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="breadcrumbs">
                    <div className="container">
                        <ol>
                            <li><a href="/">Home</a></li>
                            <li className="current">About Us</li>
                        </ol>
                    </div>
                </nav>
            </div>
            {/* <!-- End Page Title --> */}


            {/* <!-- About Section --> */}
            <section className="ais-about section">

                <div className="container">

                    <div className="row position-relative">

                        <div className="col-lg-7 about-img" data-aos="zoom-out" data-aos-delay="200"><img src={ourStory} /></div>

                        <div className="col-lg-7" data-aos="fade-up" data-aos-delay="100">
                            <h2 className="inner-title">Anantam: Your Digital Compass</h2>
                            <div className="our-story" style={{ backgroundColor: "#F0EEFF" }}>
                                <h3>Our Story</h3>
                                <p>Anantam is a vibrant organization that houses passionate innovators working towards finding the solutions of complex problems through the most advanced technologies. We specialize in custom design software to allow businesses to meet their goals. Our competencies lie in the adoption of latest technologies to bring in efficiency, scalability, and user-centric applications.</p>
                                <p> At Anantam, we prioritize strong client relationships. By understanding your unique needs and aspirations, we develop tailored solutions that drive growth and success, from concept to deployment.</p>
                                <ul>
                                    <li><i className="bi bi-check-circle"></i> <span>Client-Centric Innovation</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Technical Excellence</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Strategic Partnerships</span></li>
                                </ul>
                                <p>Let's work together to turn your dreams into reality.</p>

                                <div className="watch-video d-flex align-items-center position-relative">
                                    <span href="#contact" className="ais-btn hover-hand p-2">
                                        Get in Touch
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </section>
            {/* <!-- /About Section --> */}

            {/* <!-- Alt Services Section --> */}
            <section id="alt-services" className="alt-services section">

                <div className="container">

                    <div className="row justify-content-around gy-4">
                        <div className="features-image col-lg-6" data-aos="fade-up" data-aos-delay="100">  <img src={think} alt="" /></div>

                        <div className="col-lg-5 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                            <h3>Think IT Solutions, Think Anantam</h3>
                            <p>Anantam is your ultimate destination for innovative IT solutions. We offer a comprehensive range of services, from software development and cloud solutions to cybersecurity and digital transformation. With our team of experts and cutting-edge technology, we empower businesses to thrive in the digital age.</p>

                            <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="300">
                                <i className="bi bi-easel flex-shrink-0"></i>
                                <div>
                                    <h4><a className="stretched-link">Innovation</a></h4>
                                    <p>We embrace innovation and constantly find out new technologies for staying ahead of the curve.</p>
                                </div>
                            </div>
                            {/* <!-- End Icon Box --> */}

                            <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="400">
                                <i className="bi bi-patch-check flex-shrink-0"></i>
                                <div>
                                    <h4><a className="stretched-link">Quality</a></h4>
                                    <p>We ensure that we deliver quality solutions that fully meet the highest standards of the industry.</p>
                                </div>
                            </div>
                            {/* <!-- End Icon Box --> */}

                            <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="500">
                                <i className="bi bi-person flex-shrink-0"></i>
                                <div>
                                    <h4><a className="stretched-link">Client Satisfaction</a></h4>
                                    <p>Serve our clients' needs better than they would have ever thought possible.</p>
                                </div>
                            </div>
                            {/* <!-- End Icon Box --> */}

                            <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="600">
                                <i className="bi bi-gear-wide-connected flex-shrink-0"></i>
                                <div>
                                    <h4><a className="stretched-link">Collaboration</a></h4>
                                    <p>Collaboration is encouraged, and we work very closely with our clients to attain common goals.</p>
                                </div>
                            </div>
                            {/* <!-- End Icon Box --> */}

                        </div>
                    </div>

                </div>

            </section>
            {/* <!-- /Alt Services Section --> */}

            {/* <!-- Stats Counter Section --> */}
            <section id="stats" className="stats section ais-background">
                <div className="container section-title" data-aos="fade-up">
                    <h2>Your Trusted Global Software Partner<br /></h2>
                </div>
                <div className="container" data-aos="fade-up" data-aos-delay="100">

                    <div className="row gy-4 align-items-center">

                        <div className="col-lg-5">
                            <img src={stats} alt="" className="img-fluid" />
                        </div>

                        <div className="col-lg-7">

                            <div className="row gy-4">

                                <div className="col-lg-6">
                                    <div className="stats-item d-flex">
                                        <i className="bi bi-emoji-smile flex-shrink-0"></i>
                                        <div>
                                            <span data-purecounter-start="0" data-purecounter-end="10" data-purecounter-duration="1" className="purecounter"></span>
                                            <p><strong>Happy Clients</strong> <span></span></p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Stats Item --> */}

                                <div className="col-lg-6">
                                    <div className="stats-item d-flex">
                                        <i className="bi bi-journal-richtext flex-shrink-0"></i>
                                        <div>
                                            <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                <span data-purecounter-start="0" data-purecounter-end="25" className="purecounter">0</span>
                                                <span>+</span>
                                            </div>
                                            <p><strong>Web and Mobile app</strong> <span>designed and developed</span></p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Stats Item --> */}

                                <div className="col-lg-6">
                                    <div className="stats-item d-flex">
                                        <i className="bi bi-headset flex-shrink-0"></i>
                                        <div>
                                            <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                                                <span data-purecounter-start="0" data-purecounter-end="600" className="purecounter">0</span>
                                                <span>+</span>
                                            </div>
                                            <p><strong>Hours Of Support</strong> <span></span></p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Stats Item --> */}

                                <div className="col-lg-6">
                                    <div className="stats-item d-flex">
                                        <i className="bi bi-people flex-shrink-0"></i>
                                        <div>
                                            <span data-purecounter-start="0" data-purecounter-end="18" data-purecounter-duration="1"
                                                className="purecounter"></span>
                                            <p><strong>Engineers working at Anantam </strong> <span></span></p>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- End Stats Item --> */}

                            </div>

                        </div>

                    </div>

                </div>

            </section>
            {/* <!-- /Stats Counter Section --> */}

            {/* <!-- Alt Services 2 Section --> */}
            <section id="alt-services-2" className="alt-services-2 section">

                <div className="container">

                    <div className="row justify-content-around gy-4">

                        <div className="col-lg-6 d-flex flex-column justify-content-center order-2 order-lg-1" data-aos="fade-up" data-aos-delay="100">
                            <h3>The Anantam Way</h3>
                            <p><b>Our Mission:</b> It aims to empower businesses through innovative technology solutions, providing value that really matters for long-term partnerships.</p>
                            <p><b>Our Vision:</b> Become the leader in globally advanced technology solutions in the digital transformation arena and shape the business direction.</p>

                            <div className="row">
                                <h2>Process That We Follow</h2>
                                <div className="col-lg-6 icon-box d-flex">
                                    <i className="bi bi-easel flex-shrink-0"></i>
                                    <div>
                                        <h4>Discover</h4>
                                        <p>New technologies and approaches are always researched as breakthrough solutions.</p>
                                    </div>
                                </div>
                                {/* <!-- End Icon Box --> */}

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className="bi bi-patch-check flex-shrink-0"></i>
                                    <div>
                                        <h4>Design</h4>
                                        <p>Superior quality work to achieve excellence in every kind of work.</p>
                                    </div>
                                </div>
                                {/* <!-- End Icon Box --> */}

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className="bi bi-key flex-shrink-0"></i>
                                    <div>
                                        <h4>Build</h4>
                                        <p>Running the business honestly, transparently, and on good morals.</p>
                                    </div>
                                </div>
                                {/* <!-- End Icon Box --> */}

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className="bi bi-person flex-shrink-0"></i>
                                    <div>
                                        <h4>Deliver</h4>
                                        <p>The special needs of the customers have been understood and met.</p>
                                    </div>
                                </div>
                                {/* <!-- End Icon Box --> */}

                            </div>

                        </div>

                        <div className="features-image col-lg-5 order-1 order-lg-2" data-aos="fade-up" data-aos-delay="200">
                            <img src={anantamWay} alt="" />
                        </div>

                    </div>

                </div>

            </section>
            {/* <!-- /Alt Services 2 Section --> */}

            {/* <!-- Contact Section --> */}
            <ContactUs />
            {/* <!-- /Contact Section --> */}

        </>
    );
}
export default AboutUs;