import React, { useRef } from "react";
import ContactUs from "../components/ContactUs";
import HeroSection from "../components/HeroSection";
import ServicesSection from "../components/ServicesSection";
import HireDedicatedSection from "../components/HireDedicatedSection";
import AboutSection from "../components/AboutSection";
import StatsSection from "../components/StatsSection";
import ScrollToTop from "../components/ScrollToTop";
import Portfolio from "../components/Portfolio";


function HomePage() {
  const contactRef = useRef(null);

  const scrollToContact = () => {
    contactRef?.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      {/* <!-- Hero Section --> */}
      <HeroSection scrollToContact={scrollToContact} />
      {/* <!-- /Hero Section --> */}

      {/* <!-- Services Section --> */}
      <ServicesSection />
      {/* <!-- /Services Section --> */}

      {/* <!-- Hire Dedicated Section --> */}
      <HireDedicatedSection scrollToContact={scrollToContact} />
      {/* <!-- Hire Dedicated Section --> */}

      {/* <!-- About Section --> */}
      <AboutSection />
      {/* <!-- /About Section --> */}

      {/* <!-- Stats Section --> */}
      <StatsSection />
      {/* <!-- /Stats Section --> */}

      {/* <!-- Portfolio Section --> */}
      <Portfolio />
      {/* <!-- /Portfolio Section --> */}

      {/* <!-- Contact Section --> */}
      <ContactUs contactRef={contactRef} />
      {/* <!-- /Contact Section --> */}

      <ScrollToTop />
    </>
  );
}

export default HomePage;