import React, { useState, useEffect } from 'react';
import CWApp from "../assets/img/Services/webappDev/cwapp.png";
import splaning from "../assets/img/Services/qa-testing/splaning.png";
import upgradationMigration from "../assets/img/Services/mobileappDev/upgradationMigration.png";
import mobileappTechAdvice from "../assets/img/Services/mobileappDev/mobileappTechAdvice.png";
import techTrasformation from "../assets/img/Services/mobileappDev/techTrasformation.png";
import websiteanalytics from "../assets/img/Services/seo-digital-marketing/website-analytics.png";
import portaldevelment from "../assets/img/Hire-Developers/portaldevelment.png";
import extension from "../assets/img/Hire-Developers/extension.png";
import CMSdevelment from "../assets/img/Hire-Developers/CMSdevelment.png";
import crm from "../assets/img/Hire-Developers/crm.png";
import customiz from "../assets/img/Hire-Developers/customiz.png";
import eCommerce from "../assets/img/Hire-Developers/eCommerce.png";
import consulting from "../assets/img/Hire-Developers/consulting.png";
import webdesign from "../assets/img/Services/ui-ux/web-design.png";
import api from "../assets/img/Hire-Developers/api.png";
import FrontDev from "../assets/img/Services/webappDev/frontdev.png";
import responsivedesigne from "../assets/img/Services/ui-ux/responsivedesigne.png";
import mobiledesign from "../assets/img/Services/ui-ux/mobile-ui-design.png";
import enterprisesoftsolution from "../assets/img/Services/softwareprodEng/enterprisesoftsolution.png";
import development from "../assets/img/Hire-Developers/development.png";
import UIUX from "../assets/img/Services/webappDev/uiux.png";
import developer from "../assets/img/Hire-Developers/developer.png";
import crossPlatform from "../assets/img/Hire-Developers/cross-platform.png";
import hybridtechMobile from "../assets/img/Services/mobileappDev/hybridtechMobile.png";
import testing from "../assets/img/Hire-Developers/test.png";
import iosappDevelopment from "../assets/img/Services/mobileappDev/iosappDevelopment.png";
import migration from "../assets/img/Hire-Developers/migration.png";
import iwatch from "../assets/img/Hire-Developers/iwatch.png";
import backend from "../assets/img/Hire-Developers/backend-coding.png";
import BackDev from "../assets/img/Services/webappDev/backdev.png";
import cloud from "../assets/img/Hire-Developers/cloud.png";
import devops from "../assets/img/Hire-Developers/devops.png";
import grapicdesign from "../assets/img/Hire-Developers/graphic-design.png";
import logo from "../assets/img/Hire-Developers/logo.png";
import optimization from "../assets/img/Hire-Developers/optimization.png";
import mobiletesting from "../assets/img/Services/qa-testing/mobile-testing.png";
import automated from "../assets/img/Services/qa-testing/automated.png";
import robust from "../assets/img/Services/qa-testing/robust.png";
import quality from "../assets/img/quality.png";
import skilled from "../assets/img/skilled.png";
import dedicated from "../assets/img/dedicated.png";
import agile from "../assets/img/agile.png";
import data from '../data.json'
import ContactUs from '../components/ContactUs';
import { useParams } from 'react-router-dom';

// console.log("data", data);

const icons = {
  CWApp, splaning, upgradationMigration, mobileappTechAdvice, techTrasformation, websiteanalytics, agile, dedicated, skilled, quality, CMSdevelment, extension, portaldevelment
  , eCommerce, customiz, crm, consulting, webdesign, api, FrontDev, responsivedesigne, mobiledesign, enterprisesoftsolution, development, UIUX, developer, mobiletesting,
  crossPlatform, hybridtechMobile, testing, iosappDevelopment, iwatch, migration, BackDev, backend, cloud, devops, grapicdesign, logo, optimization, automated, robust
};


function HireDeveloper() {
  const { framework } = useParams();  
  const [pageData, setPageData] = useState(null);
  
  useEffect(() => {
    setPageData(data[framework]); // Fetch the correct data based on framework
  }, [framework]);

  if (!pageData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {/* <!-- Page Title -->  */}
      <div className="page-title">
        <div className="heading cover-hire">
          <div className="container">
            <div className="row d-flex justify-content-center text-center">
              <div className="col-lg-8">
                <h1>{pageData.pageTitle.heading}</h1>
                <p className="mb-0">{pageData.pageTitle.description}</p>
              </div>
            </div>
          </div>
        </div>
        <nav className="breadcrumbs">
          <div className="container">
            <ol>
              {pageData.pageTitle.breadcrumbs.map((crumb, index) => (
                <span key={index}>
                  <li className="current"><a href={crumb.link}>{crumb.label}</a>{index < pageData.pageTitle.breadcrumbs.length - 1 ? '  \u00A0\u00A0 / \u00A0\u00A0  ' : ''} </li>
                </span>
              ))}
            </ol>
          </div>
        </nav>
      </div>
      {/* <!-- End Page Title --> */}

      {/* <!-- Why Us Section --> */}
      <section id="why-us" className="why-us section">

        {/* Section Title */}
        <div className="container section-title" data-aos="fade-up">
          <h2>{pageData.weProvide.heading}</h2>
        </div>
        {/* <!-- End Section Title --> */}

        <div className="container">
          <div className="row no-gutters">
            {pageData.weProvide.whyUs.map((item, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 card"
                data-aos="fade-up"
                data-aos-delay={item.delay}
              >
                <span>{item.number}</span>
                <h4>{item.title}</h4>
                <p>{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <!-- /Why Us Section --> */}

      {/* <!-- Advantages Section --> */}
      <section id="" className="advantage section ais-background">
        {/* Section Title */}
        <div className="container section-title" data-aos="fade-up">
          <h2>{pageData.services.servicesHeading.heading}</h2>
        </div>
        {/* <!-- End Section Title --> */}

        <div className="container">
          <div className="row gy-4">
            {pageData.services.servicesHeading.servicesdetails.map((item) => (
              <div
                className="col-lg-6"
                key={item.id}
                data-aos="fade-up"
                data-aos-delay={item.delay}
              >
                <div className="service-item d-flex">
                  <div className="icon flex-shrink-0">
                    <i>
                      <img src={icons[item.icon]} alt={item.title} />
                    </i>
                  </div>
                  <div>
                    <h4 className="title">
                      <a className="stretched-link">{item.title}</a>
                    </h4>
                    <p className="description">{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <!-- /Advantages Section --> */}

      {/* <!-- About Section --> */}
      <section id="" className="about section light-background">
        <div className="container" data-aos="fade-up" data-aos-delay="100">
          <div className="row align-items-xl-center gy-5">
            {/* Section Heading */}
            <div className="col-xl-5 content section-title">
              <h3>{pageData.whyAnantam.heading}</h3>
              <h2>{pageData.whyAnantam.subheading}</h2>
            </div>

            {/* Icon Boxes */}
            <div className="col-xl-7">
              <div className="row gy-4 icon-boxes">
                {pageData.whyAnantam.features.map((item, index) => (
                  <div
                    className="col-md-6"
                    key={index}
                    data-aos="fade-up"
                    data-aos-delay={item.delay}
                  >
                    <div className="icon-box">
                      <i>
                        <img src={icons[item.icon]} alt={item.title} />
                      </i>
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- /About Section --> */}

      {/* <!-- Contact Section --> */}
      <ContactUs />
      {/* <!-- /Contact Section --> */}

    </>
  );
}

export default HireDeveloper;
