import React from "react";
import tta from "../assets/img/portfolio/TTA/TTA.jpg";
import nict from "../assets/img/portfolio/NICT/NICT.jpg";
import sanavi from "../assets/img/portfolio/SANAVI/sanavi.jpg";
import { Link } from "react-router-dom";

function Portfolio() {
    const handleClick = () => {
        window.scrollTo(0, 0);
    };
    return (
        <>
            <section id="portfolio" class="portfolio section">

                {/* <!-- Section Title --> */}
                <div class="container section-title" data-aos="fade-up">
                    <h2>Portfolio</h2>
                    {/* <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p> */}
                </div>
                {/* <!-- End Section Title --> */}

                <div class="container">

                    <div class="isotope-layout" data-default-filter="*" data-layout="masonry" data-sort="original-order">

                        {/* <ul class="portfolio-filters isotope-filters" data-aos="fade-up" data-aos-delay="100">
                            <li data-filter="*" class="filter-active">All</li>
                            <li data-filter=".filter-app">App</li>
                            <li data-filter=".filter-product">Product</li>
                            <li data-filter=".filter-branding">Branding</li>
                            <li data-filter=".filter-books">Books</li>
                        </ul> */}
                        {/* <!-- End Portfolio Filters --> */}

                        <div class="row gy-4 isotope-container" data-aos="fade-up" data-aos-delay="200">

                            <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
                                <div class="portfolio-content h-100">
                                    <Link to="/portfolio-details" onClick={handleClick} data-gallery="portfolio-gallery-app" class="glightbox"><img src={tta} class="img-fluid" alt="" /></Link>
                                    <div class="portfolio-info">
                                        <Link to="/portfolio-details" onClick={handleClick}><h4><a title="More Details">Technical Training Alliance</a></h4></Link>
                                        {/* <h4><a href="portfolio-details.html" title="More Details">Technical Training Alliance</a></h4> */}
                                        <p>This platform offers a wide variety of courses on a variety of topics.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Portfolio Item --> */}

                            <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-product">
                                <div class="portfolio-content h-100">
                                    <Link to="/nict-details" onClick={handleClick} data-gallery="portfolio-gallery-app" class="glightbox"><img src={nict} class="img-fluid" alt="" /></Link>
                                    <div class="portfolio-info">
                                        <Link to="/nict-details" onClick={handleClick}><h4><a title="More Details">National Institute of Computer Technology</a></h4></Link>
                                        <p>Advanced Organizational Management Solution with Online Exams.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Portfolio Item --> */}

                            <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-branding">
                                <div class="portfolio-content h-100">
                                    <Link to="/sanavi-details" onClick={handleClick} data-gallery="portfolio-gallery-app" class="glightbox"><img src={sanavi} class="img-fluid" alt="" /></Link>
                                    <div class="portfolio-info">
                                        <Link to="/sanavi-details" onClick={handleClick}><h4><a title="More Details">Sanavi Infrabuildcon</a></h4></Link>
                                        <p>Website that showcases their infrastructure, safety measures, and work portfolio</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End Portfolio Container --> */}

                    </div>

                </div>

            </section>
        </>

    );
}
export default Portfolio;