import React, { useState, useEffect } from 'react';
import CWApp from "../assets/img/Services/webappDev/cwapp.png";
import splaning from "../assets/img/Services/qa-testing/splaning.png";
import upgradationMigration from "../assets/img/Services/mobileappDev/upgradationMigration.png";
import mobileappTechAdvice from "../assets/img/Services/mobileappDev/mobileappTechAdvice.png";
import techTrasformation from "../assets/img/Services/mobileappDev/techTrasformation.png";
import websiteanalytics from "../assets/img/Services/seo-digital-marketing/website-analytics.png";
import quality from "../assets/img/quality.png";
import skilled from "../assets/img/skilled.png";
import dedicated from "../assets/img/dedicated.png";
import agile from "../assets/img/agile.png";

function HireDeveloper() {
    return (
        <>
<div className="page-title">
<div className="heading cover-hire">
    <div className="container">
        <div className="row d-flex justify-content-center text-center">
            <div className="col-lg-8">
                <h1> Hire Next.js Developers</h1>
                <p className="mb-0">Enhance business performance and accelerate growth with our next-level Next.js development services.</p>
            </div>
        </div>
    </div>
</div>
<nav className="breadcrumbs">
    <div className="container">
        <ol>
            <li><a href="/">Home</a></li>
            <li className="current">Portfolio Details</li>
        </ol>
    </div>
</nav>
</div>
<section id="why-us" class="why-us section">
<div class="container">
    <div class="row no-gutters">
        <div class="col-lg-4 col-md-6 card" data-aos="fade-up" data-aos-delay="100">
            <span>01</span>
            <h4>Defining Requirement</h4>
            <p>We gather project requirements, including functional, non-functional, and end-goal specifications. Our Next.js development team then crafts a comprehensive project plan, strategy, and technical documentation.</p>
        </div>
        <div class="col-lg-4 col-md-6 card" data-aos="fade-up" data-aos-delay="200">
            <span>02</span>
            <h4>Screening and interviewing</h4>
            <p>Interview our certified, experienced Next.js developers and select the best fit for your project. Our developers can work full-time, part-time, or on an ad-hoc basis, and can even integrate seamlessly into your product development team.</p>
        </div>
        <div class="col-lg-4 col-md-6 card" data-aos="fade-up" data-aos-delay="300">
            <span>03</span>
            <h4>Hiring</h4>
            <p>Select from various engagement models to hire Next.js developers with the right level of experience and flexibility to meet your business requirements.</p>
        </div>
    </div>
</div>
</section>
<section id="" className="advantage section">
<div className="container section-title" data-aos="fade-up">
    <h2>Partner with the Best: Next.js App Development Experts</h2>
</div>
<div className="container">
    <div className="row gy-4">
        <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="100">
            <div className="service-item d-flex">
                <div className="icon flex-shrink-0"><i ><img src={CWApp} alt="" /></i></div>
                <div>
                    <h4 className="title"><a className="stretched-link">Custom Development</a></h4>
                    <p className="description">Our skilled Next.js developers craft bespoke, dynamic, and high-quality web solutions perfectly aligned with your unique business objectives.</p>
                </div>
            </div>
        </div>
        <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="200">
            <div className="service-item d-flex">
                <div className="icon flex-shrink-0"><i><img src={splaning} alt="" /></i></div>
                <div>
                    <h4 className="title"><a className="stretched-link">Strategic Consulting</a></h4>
                    <p className="description">Our expert team provides in-depth architectural analysis to develop scalable and secure Next.js web applications, ensuring long-term success.</p>
                </div>
            </div>
        </div>
        <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="300">
            <div className="service-item d-flex">
                <div className="icon flex-shrink-0"><i><img src={upgradationMigration} /></i></div>
                <div>
                    <h4 className="title"><a className="stretched-link">Seamless Integration</a></h4>
                    <p className="description">Our developers seamlessly integrate analytical mechanisms into your existing applications, leveraging their deep understanding of Next.js.</p>
                </div>
            </div>
        </div>
        <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="400">
            <div className="service-item d-flex">
                <div className="icon flex-shrink-0"><i><img src={mobileappTechAdvice} /></i></div>
                <div>
                    <h4 className="title"><a className="stretched-link">Comprehensive Support</a></h4>
                    <p className="description">We offer comprehensive support and maintenance services, including enhancements, updates, and backups, to keep your Next.js applications running optimally.</p>
                </div>
            </div>
        </div>
        <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="500">
            <div className="service-item d-flex">
                <div className="icon flex-shrink-0"><i><img src={techTrasformation} /></i></div>
                <div>
                    <h4 className="title"><a className="stretched-link">Modernization Services</a></h4>
                    <p className="description">Upgrade your existing business solutions to the latest Next.js technology, ensuring future-readiness and improved performance.</p>
                </div>
            </div>
        </div>
        <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="600">
            <div className="service-item d-flex">
                <div className="icon flex-shrink-0"><i><img src={websiteanalytics} /></i></div>
                <div>
                    <h4 className="title"><a className="stretched-link">High-Performance SSR</a></h4>
                    <p className="description">Our Next.js experts specialize in developing server-side rendered web applications that deliver lightning-fast load times and exceptional user experiences.</p>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
<section id="" className="about section light-background">

<div className="container" data-aos="fade-up" data-aos-delay="100">
    <div className="row align-items-xl-center gy-5">

        <div className="col-xl-5 content">
            <h3>Why Anantam?</h3>
            <h2> Crafting Exceptional Next.js Apps: Your Vision, Our Expertise </h2>
        </div>
        <div className="col-xl-7">
            <div className="row gy-4 icon-boxes">
                <div className="col-md-6" data-aos="fade-up" data-aos-delay="200">
                    <div className="icon-box">
                        <i><img src={agile} /></i>
                        <h3>Agile Methodology</h3>
                        <p>Leveraging agile principles, our seasoned Next.js developers ensure timely project delivery without compromising quality.</p>
                    </div>
                </div>
                <div className="col-md-6" data-aos="fade-up" data-aos-delay="300">
                    <div className="icon-box">
                        <i> <img src={dedicated} /> </i>
                        <h3>Dedicated Expertise</h3>
                        <p>Benefit from a committed team of experienced Next.js developers tailored to your specific project needs.</p>
                    </div>
                </div>
                <div className="col-md-6" data-aos="fade-up" data-aos-delay="400">
                    <div className="icon-box">
                        <i><img src={skilled} /></i>
                        <h3>Skilled Workforce</h3>
                        <p>Anantam's offshore developers specialize in crafting innovative, custom applications that propel digital transformation.</p>
                    </div>
                </div>
                <div className="col-md-6" data-aos="fade-up" data-aos-delay="500">
                    <div className="icon-box">
                        <i><img src={quality} /></i>
                        <h3>Premium Quality</h3>
                        <p>Experience top-tier development services, including pristine code, robust security measures, and punctual project completion within specified timelines and budgets.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
</>
    );
}
export default HireDeveloper;
