import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/img/logo.png";
import data from "../data.json";

const dropdownItems = [
  {
    title: "Hire Backend Developers",
    links: [
      { text: "Hire PHP Developers", path: "/hire-developer/php-developers" },
      { text: "Hire Laravel Developers", path: "/hire-developer/laravel-developers" },
      { text: "Hire Node.js Developers", path: "/hire-developer/node-js-developers" },
      { text: "Hire Express.js Developers", path: "/hire-developer/express-js-developers" },
    ],
  },
  {
    title: "Hire Frontend Developers",
    links: [
      { text: "Hire HTML Developers", path: "/hire-developer/html-developers" },
      { text: "Hire NextJs Developers", path: "/hire-developer/next-js-developers" },
      { text: "Hire Angular Developers", path: "/hire-developer/angular-developers" },
      { text: "Hire VueJs Developers", path: "/hire-developer/vue-js-developers" },
      { text: "Hire React Developers", path: "/hire-developer/react-developers" },
    ],
  },
  {
    title: "Hire Open Source Developers",
    links: [
      { text: "Hire Flutter Developers", path: "/hire-developer/flutter-developers" },
      { text: "Hire React Native Developers", path: "/hire-developer/react-native-developers" },
      { text: "Hire iOS Developers", path: "/hire-developer/ios-developers" },
    ],
  },
  {
    title: "Other Services",
    links: [
      { text: "Hire Full Stack Developers", path: "/hire-developer/full-stack-developers" },
      { text: "Hire UX/UI Designers", path: "/hire-developer/ux-ui-developers" },
      { text: "Hire QA Testers", path: "/hire-developer/qa-tester" },
    ],
  },
];

const Header = () => {
  console.log("data", data);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const closeDropdownAndRefresh = (path) => {
    if (path) {
      window.location.href = path; // Or use `navigate` if React Router is being used
    }
    setIsOpen(false);
  };

  return (
    <header id="header" className="header fixed-top">
      <TopBar />
      <div className="container-fluid py-3">
        <div className="container branding d-flex align-items-center py-2">
          <div className="container position-relative d-flex align-items-center justify-content-between">
            <a href="/" className="logo d-flex align-items-center">
              <img src={Logo} alt="Anantam Logo" />
            </a>
            <NavMenu
              isOpen={isOpen}
              toggleDropdown={toggleDropdown}
              closeDropdownAndRefresh={closeDropdownAndRefresh}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

const TopBar = () => (
  <div className="topbar d-flex align-items-center">
    <div className="container d-flex justify-content-center justify-content-md-between">
      <div className="contact-info d-flex align-items-center">
        <i className="bi bi-envelope d-flex align-items-center">
          <a href="mailto:hello@anantamit.com">hello@anantamit.com</a>
        </i>
        <i className="bi bi-phone d-flex align-items-center ms-4">
          <span>+91 972727-0155</span>
        </i>
      </div>
      <div className="social-links d-none d-md-flex align-items-center">
        <a href="https://www.linkedin.com/company/anantam-it-solutions/" target="_blank" className="linkedin">
          <i className="bi bi-linkedin"></i>
        </a>
        <a href="#" target="_blank" className="facebook">
          <i className="bi bi-facebook"></i>
        </a>
        <a href="https://www.instagram.com/anantam_it" target="_blank" className="instagram">
          <i className="bi bi-instagram"></i>
        </a>
      </div>
    </div>
  </div>
);

const NavMenu = ({ isOpen, toggleDropdown, closeDropdownAndRefresh }) => (
  <nav id="navmenu" className="navmenu">
    <ul>
      <li>
        <Link to="/" className="active">
          Home
        </Link>
      </li>
      <li>
        <a href="#services">Services</a>
      </li>
      {/* <li>
        <a href="/">Hire Dedicated Developers</a>
      </li> */}
      {/* <li>
        <a href="/about-us">About Us</a>
      </li> */}
      <li className="listing-dropdown">
        <a className="hover-hand" onClick={toggleDropdown}>
          <span>Hire Dedicated Developers</span>
          <i className={`bi bi-chevron-${isOpen ? "down" : "down"} toggle-dropdown`} />
        </a>
        {isOpen && (
          <ul style={{ alignItems: "start" }}>
            {dropdownItems.map((category, index) => (
              <li key={index}>
                <Link className="ais-colour">{category.title}</Link>
                {category.links.map((link, idx) =>
                  link.text.trim() ? (
                    <Link key={idx} onClick={() => closeDropdownAndRefresh(link.path)}>
                      {link.text}
                    </Link>
                  ) : (
                    <span key={idx}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  )
                )}
              </li>
            ))}
          </ul>
        )}
      </li>
      <li class="dropdown"><a className="hover-hand"><span>Company</span> <i class="bi bi-chevron-down toggle-dropdown"></i></a>
        <ul>
          <Link onClick={() => closeDropdownAndRefresh('/about-us')}>About Us</Link>
          <Link onClick={() => closeDropdownAndRefresh('/careers-at-anantam')}>Career</Link>
        </ul>
      </li>
      <li>
        <span href="#contact" className="ais-btn hover-hand p-2">
          Contact Us
        </span>
      </li>
    </ul>
    <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
  </nav>
);

export default Header;
